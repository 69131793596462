export interface IClientSecret {
    displayName: string;
    endDateTime: string;   
    hint: string;
    keyId: string;
    customKeyIdentifier: string;
}

export class ClientSecret implements IClientSecret {
    displayName: string;
    endDateTime: string;
    hint: string;
    keyId: string;
    customKeyIdentifier: string;
}

export class DisplayClientSecret {
    
    constructor (clientSecret: IClientSecret) {
        this.description = clientSecret.displayName ?? atob(clientSecret.customKeyIdentifier);
        this.expires = clientSecret.endDateTime;
        this.value = (clientSecret.hint) ? clientSecret.hint + "***" : "Hidden";
        this.secretId = clientSecret.keyId;
    }
    
    description: string;
    expires: string;
    value: string;
    secretId: string;
}