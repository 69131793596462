<form class="branded" *ngIf="!IsSearching && SearchResults.length === 0">

    <p>Please use any number of the search fields below:</p>

    <div class="row">

        <div class="col-12 col-sm-6 col-lg-4 p-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label class="font-gotham-narrow-bold text-dark-blue">email</mat-label>
                <input matInput [formControl]="email" (keyup.enter)="search()">
                <mat-error *ngIf="email.invalid">Please enter a valid email address.</mat-error>
                </mat-form-field>
        </div>

        <div class="col-12 col-sm-6 col-lg-4 p-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label class="font-gotham-narrow-bold text-dark-blue">first name</mat-label>
                <input matInput [formControl]="firstName" (keyup.enter)="search()">
                <mat-error *ngIf="firstName.invalid">{{getErrorMessage("firstName")}}</mat-error>
                </mat-form-field>
        </div>

        <div class="col-12 col-sm-6 col-lg-4 p-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label class="font-gotham-narrow-bold text-dark-blue">last name</mat-label>
                <input matInput [formControl]="lastName" (keyup.enter)="search()">
                <mat-error *ngIf="lastName.invalid">{{getErrorMessage("lastName")}}</mat-error>
                </mat-form-field>
        </div>
    </div>
    <div class="row-center-items">
        <div class="col-12 col-sm-6 col-lg-4 p-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label class="font-gotham-narrow-bold text-dark-blue">provisioning id</mat-label>
                <input matInput [formControl]="provisioningId" (keyup.enter)="search()">
                <mat-error *ngIf="provisioningId.invalid">This field may only contain numbers.</mat-error>
                </mat-form-field>
        </div>

        <div class="col-12 col-sm-6 col-lg-4 p-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label class="font-gotham-narrow-bold text-dark-blue">city</mat-label>
                <input matInput [formControl]="city" (keyup.enter)="search()">
                <mat-error *ngIf="city.invalid">This field may only contain letters.</mat-error>
                </mat-form-field>                  
        </div>
    </div>

    <p class="text-danger text-center">{{screenMessage}}</p>

    <div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar">
        <div class="btn-group mr-2" role="group">
            <button class="button" type="button" (click)="search()">search</button>
        </div>
        <div class="btn-group mr-2" role="group">
            <button class="button" type="button" (click)="reset()">reset</button>                                 
        </div>
        <div class="btn-group mr-2" role="group"  [hidden]="!isDialog">
            <button class="button" type="button" mat-dialog-close>close</button>                                 
        </div>
    </div>

</form>

<div class="container" *ngIf="IsSearching">
    <div class="row justify-content-center col-sm-12">
        <p>Searching for users.</p>                               
    </div>
    <div class="row justify-content-center col-sm-12">
        <mat-spinner></mat-spinner>                               
    </div>                                
</div>

<div class="col-12" [hidden]="SearchResults.length === 0">

    <h4 id="resultCount">{{ dataSource.data.length }} user(s) found</h4>

    <table mat-table [dataSource]="dataSource" aria-describedby="resultCount" matSort matSortActive="displayName">

        <ng-container matColumnDef="displayName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> displayName </th>
            <td mat-cell *matCellDef="let user"> {{user.displayName}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> email </th>
            <td mat-cell *matCellDef="let user"> {{user.email}} </td>
        </ng-container>

        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> city </th>
            <td mat-cell *matCellDef="let user"> {{user.locationCity}} </td>
        </ng-container>

        <ng-container matColumnDef="provisioningId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> provisioningId </th>
            <td mat-cell *matCellDef="let user"> {{user.provisioningId}} </td>
        </ng-container>

        <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> status </th>
            <td mat-cell *matCellDef="let user"> 
                <span [ngClass]="{'inactive': !user.isActive, 'active': user.isActive}" > {{!user.isActive ?  'disabled' : 'enabled'}}</span>
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row (click)="selectRow(row)" *matRowDef="let row; columns: displayedColumns;"></tr>        

    </table>

    <mat-paginator #searchResultsPaginator [pageSizeOptions]="[10, 20, 40]" showFirstLastButtons></mat-paginator>
    
    <div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar">
        <div class="btn-group mr-2" role="group">
            <button class="button" type="button" (click)="back()">back</button>                                 
        </div>
        <div class="btn-group mr-2" role="group"  [hidden]="!isDialog">
            <button class="button" type="button" mat-dialog-close>close</button>                                 
        </div>
    </div>

</div>


