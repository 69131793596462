import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageLayout } from '@cvx/nextpage';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { IManageableUser, PrimaryUser } from 'src/app/models/primaryUser';
import { MatDialog } from '@angular/material/dialog';
import { DialogPrimaryUserSearchComponent } from '../dialog-primary-user-search/dialog-primary-user-search.component';
import { PrimaryUserService } from 'src/app/services/primaryUser/primaryUser.service';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { IDefaultApiResponse } from 'src/app/models/defaultApiResponse';
import { DialogLocationSearchComponent } from '../dialog-location-search/dialog-location-search.component';
import { ChevronLocation } from 'src/app/models/locationModel';
import { LocationService } from 'src/app/services/locationService/locationService';
import { DialogCampEntitySearchComponent } from '../dialog-camp-entity-search/dialog-camp-entity-search.component';
import { CampEntity } from 'src/app/models/campEntity';
import { CampService } from 'src/app/services/campService/campService';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { FormService } from 'src/app/services/form.service';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';
import { PrimaryUserAuthService } from 'src/app/services/primaryUserAuth/primaryUserAuth.service';
import { PrimaryUserMsg } from 'src/app/constants/PrimaryUserMsg.constants';

@Component({
  selector: 'app-primary-user-details',
  templateUrl: './primary-user-details.component.html',
  styleUrls: ['./primary-user-details.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, 
      MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }, 
    {
        provide: MAT_DATE_FORMATS, useValue: {
            parse: {
                dateInput: "L",
            },
            display: {
                dateInput: "L",
                monthYearLabel: "MMM YYYY",
                dateA11yLabel: "LL",
                monthYearA11yLabel: "MMMM YYYY",
            },
        }
    }]
})
export class PrimaryUserDetailsComponent implements OnInit {

  @Input() operationType: string = "view";
  @Input() user!: PrimaryUser;
  @Input() isAllowedToEditName: boolean = false;
  @Input() fromManageUser: boolean = false;
  @Output() cancelMe: EventEmitter<boolean> = new EventEmitter<boolean>();

  PageLayout = PageLayout;
  personType = "Employee";
  route = this.router.url

  isUpdating = false
  isReadonly = false
  isEmployeeOnReactivateUser = false
  isEmployeeOnDeactivateUser = false
  isEmployeeOnMyProfile = false
  isEmployeeOnManageUser = false

  isContractorOrEmployeeJVPartnerOnMyProfile = false;
  isContractorOrEmployeeJVPartnerOnManageUser = false;
  isContractorOrEmployeeJVPartnerOnSearchUser = false;

  errorMessage = "";
  screenMessage = "";

  errorMessageEmergencyContactDetails = "";
  screenMessageEmergencyContactDetails = "";

  hide = true;

  minDate = new Date();
  maxDate = new Date();

  userDetailsForm = this.formService.GetPrimaryUserForm();
  userIsActive = false;
  isAllowedToManageUser = false;
  isInvalidAccountEndDate = false;

  constructor(
    private authService: CalAngularService, 
    private dialog: MatDialog, 
    private primaryUserService: PrimaryUserService, 
    private primaryUserAuthService: PrimaryUserAuthService,
    private locationService: LocationService, 
    private campService: CampService, 
    private router: Router,
    private dialogService: DialogService,
    private formService: FormService,
    private cdref: ChangeDetectorRef ) { }


  ngOnInit(){
    this.initializedForm();
    this.isReadonly = this.operationType === "view";
    this.userIsActive = this.operationType === 'edit' && this.user.isActive;
    this.isEmployeeOnMyProfile = this.operationType === "myProfile" && this.user.personType === "Employee";
    this.isEmployeeOnManageUser = this.operationType === "edit" && this.user.personType === "Employee";
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  async initializedForm(): Promise<void>  {
    const token = await lastValueFrom(this.authService.getAADToken(environment.tokenScopes));
    if (this.operationType !== "myProfile") {
      this.userDetailsForm.removeControl("personalTelephoneNumber");
      this.userDetailsForm.removeControl("personalTelephoneNumberAlt");
      this.userDetailsForm.removeControl("personalEmail");
    }
    

    if (this.operationType !== "create") {
      this.formService.PatchPrimaryUserFormWithUserObject(this.userDetailsForm, this.user);
      this.formService.MarkPrimaryUserFormAsTouched(this.userDetailsForm)

      if (this.user.personType === "Employee") {
        this.userDetailsForm.removeControl("sourcingCompany");
        this.userDetailsForm.removeControl("sourcingCompanyId");
        this.userDetailsForm.removeControl("sourcingCompanyDisplay");
        this.userDetailsForm.removeControl("contractortype");
      }

      this.isContractorOrEmployeeJVPartnerOnMyProfile = (this.user.personType === 'Contractor' || this.user.personType === 'JV Partner') && this.operationType === 'myProfile';
      this.isContractorOrEmployeeJVPartnerOnManageUser = (this.user.personType === 'Contractor' || this.user.personType === 'JV Partner') && this.operationType === 'edit';
      this.isContractorOrEmployeeJVPartnerOnSearchUser = (this.user.personType !== 'Employee') && this.operationType === 'view';

      if(this.operationType === 'edit') {
        const user = this.userDetailsForm.value;

        this.getManageUserAuthorization(this.authService.cvxClaimsPrincipal, {
          sponsor: user.sponsor,
          supervisor: user.supervisor,
          workcompany: user.workcompany,
          hrLevel2code: user.hrLevel2code,
          hrLevel5code: user.hrLevel5code,
        });
      }

      this.resolveProvIdToDisplayName(this.user?.sponsor, token, "sponsorDisplayName");
      this.resolveProvIdToDisplayName(this.user?.supervisor, token, "supervisorDisplayName");
      this.resolveProvIdToDisplayName(this.user?.approver, token, "approverDisplayName");
      this.resolveLocationIdToName(this.user?.locationId, token);
    } else {
      this.formService.addCreateUserValidator(this.userDetailsForm)
      this.userDetailsForm.removeControl("provisioningId");
    }

    this.isEmployeeOnReactivateUser = !this.user?.isActive && this.user?.personType === "Employee";
    this.isEmployeeOnDeactivateUser = this.user?.isActive && this.user?.personType === "Employee";

    this.minDate.getDate = Date.now;
    this.maxDate.setFullYear(this.maxDate.getFullYear() + 1);
    this.errorMessage = "";
    
  }

  resetForm() {
    this.cancelMe.emit(true)
  }

  searchForPrimaryUser(type: string): void {
    let personType = "";

    if (type === "sponsor") {
      personType = "Employee"
    }

    const dialogRef = this.dialog.open(DialogPrimaryUserSearchComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%',
      data: personType,
    });

    dialogRef.afterClosed().subscribe(result => {
      
      if (this.operationType !== "create" &&  result?.data?.provisioningId === this.user.provisioningId) {
        this.showUserSelfAssignmentError(type);
      } else if (result?.data) {
        if (type === "sponsor") {
          this.userDetailsForm.patchValue({ 
            sponsor: result.data.provisioningId,
            sponsorDisplayName: result.data.displayName
          })
        }
  
        if (type === "supervisor") {
          this.userDetailsForm.patchValue({ 
            supervisor: result.data.provisioningId,
            supervisorDisplayName: result.data.displayName
          })
        }
  
        if (type === "approver") {
          this.userDetailsForm.patchValue({ 
            approver: result.data.provisioningId,
            approverDisplayName: result.data.displayName
          })
        }
      }
    });
  }

  showUserSelfAssignmentError(type: string) {
    const relatedFieldName = type
    const fieldName = type + "DisplayName"

    this.userDetailsForm.patchValue({
      [relatedFieldName]: 'self-assigned',
      [fieldName]: null
    })

    this.userDetailsForm.controls[fieldName].markAsTouched()
  }

  async resolveProvIdToDisplayName(provId: string, token: any, fieldName: string) {

    const relatedFieldName = fieldName.replace("DisplayName","");

    const observer = {
      next: (x: IDefaultApiResponse) => {
        let user = x.data as PrimaryUser
        if (user.provisioningId === this.user.provisioningId) {
          this.userDetailsForm.patchValue({
            [relatedFieldName]: "self-assigned",
            [fieldName]: null            
          });

          this.userDetailsForm.controls[fieldName].markAsTouched()
        } else {    
          this.userDetailsForm.patchValue({ [fieldName]: user.displayName });
        }
      },
      error: (err: any) => {
        if (err.status === 404) {
          this.userDetailsForm.patchValue({ 
            [relatedFieldName]: "invalid",
            [fieldName]: null            
          });
          this.userDetailsForm.controls[fieldName].markAsTouched()
        }
        else if (err.status === 400) {
          this.dialogService.OpenPopUpDialog("error", err.error.message)
          this.userDetailsForm.patchValue({
            [relatedFieldName]: "duplicate",
            [fieldName]: null
          });
          this.userDetailsForm.controls[fieldName].markAsTouched()
        }
        else {
          this.userDetailsForm.patchValue({
            [fieldName]: "Error resolving user"  
          });
        }
      }
    };

    let apiCall = this.primaryUserService.GetPrimaryUser(provId, token, "active")
    if (apiCall)
      apiCall.subscribe(observer)
  }

  //#region primary user functions

  async updateUser() {

    this.errorMessage = "";

    if(this.userDetailsForm.value.personType === 'JV Partner') {
      this.userDetailsForm.patchValue({
        contractortype: 1
      })
      this.userDetailsForm.controls['contractortype'].markAsTouched()
    } 

    if (this.checkSourcingCompany()) {
      this.errorMessage = this.operationType === 'myProfile' ? PrimaryUserMsg.INCOMPLETE_PROFILE_ERROR_MSG : PrimaryUserMsg.INVALID_USERDETAILS_FORM_MSG;
      return
    }
   
    if (!this.userDetailsForm.valid || !this.isValidNumberForContractorType()) {
      this.errorMessage = PrimaryUserMsg.INVALID_USERDETAILS_FORM_MSG;
      return;
    }

    this.isUpdating = true
    let token = await lastValueFrom(this.authService.getAADToken(environment.tokenScopes));
    
    const observer = {
      next: (x: IDefaultApiResponse) => {
        this.isUpdating = false;
        this.dialogService.OpenPopUpDialog("done", x.message)
      },
      error: (err: any) => {
        this.isUpdating = false;
        if (err.error.message !== undefined) {
          this.dialogService.OpenPopUpDialog("error",  err.error.message)
        } else {
          this.dialogService.OpenPopUpDialog("error", err.message)
        }
      }
    };

    this.userDetailsForm.value.telephoneNumberAlt = typeof this.userDetailsForm.value.telephoneNumberAlt === 'undefined' ? '' : this.userDetailsForm.value.telephoneNumberAlt;
    this.userDetailsForm.value.telephoneNumber = typeof this.userDetailsForm.value.telephoneNumber === 'undefined' ? '' : this.userDetailsForm.value.telephoneNumber;
    this.userDetailsForm.value.mobileNumber = typeof this.userDetailsForm.value.mobileNumber === 'undefined' ? '' : this.userDetailsForm.value.mobileNumber;
    this.userDetailsForm.value.personalTelephoneNumber = typeof this.userDetailsForm.value.personalTelephoneNumber === 'undefined' ? '' : this.userDetailsForm.value.personalTelephoneNumber;
    this.userDetailsForm.value.personalTelephoneNumberAlt = typeof this.userDetailsForm.value.personalTelephoneNumberAlt === 'undefined' ? '' : this.userDetailsForm.value.personalTelephoneNumberAlt;

    if (this.operationType === "myProfile") {
      let apiCall = this.primaryUserService.UpdateMe(this.userDetailsForm.value, token)
      if(apiCall)
        apiCall.subscribe(observer)  
    } else {
      let apiCall = this.primaryUserService.UpdateUser(this.userDetailsForm.value, token)
      if(apiCall)
        apiCall.subscribe(observer)      
    }
  }

  checkSourcingCompany(){
    return (!this.userDetailsForm.value.sourcingCompany || !this.userDetailsForm.value.sourcingCompanyId) &&  this.userDetailsForm.value.personType !== 'Employee';
  }

  async reactivateUser() {
    const accountEndDate = moment(this.userDetailsForm.value.accountEndDate);
    const newDate = moment().add(7, 'days'); 

    this.isInvalidAccountEndDate = accountEndDate.isBefore(newDate);

    if(this.userDetailsForm.value.personType === 'JV Partner') {
      this.userDetailsForm.patchValue({
        contractortype: 1
      })
      this.userDetailsForm.controls['contractortype'].markAsTouched()
    } 

    if (this.checkSourcingCompany()) {
      this.errorMessage = this.operationType === 'myProfile' ? PrimaryUserMsg.INCOMPLETE_PROFILE_ERROR_MSG : PrimaryUserMsg.INVALID_USERDETAILS_FORM_MSG;
      return
    }

    if (this.userDetailsForm.valid && !this.isInvalidAccountEndDate && this.isValidNumberForContractorType()) {  

      this.isUpdating = true
    
      const token = await lastValueFrom(this.authService.getAADToken(environment.tokenScopes));

      const observer = {
        next: (x: IDefaultApiResponse) => {
          this.isUpdating = false;
          this.userIsActive = true;
          this.errorMessage = "";
          this.dialogService.OpenPopUpDialog("done", x.message)
        },
        error: (err: any) => {
          this.isUpdating = false;
          if (err.error.message !== undefined) {
            this.dialogService.OpenPopUpDialog("error",  err.error.message)
          } else {
            this.dialogService.OpenPopUpDialog("error", err.message)
          }
        }
      };

      let apiCall = this.primaryUserService.ReactivateUser(this.userDetailsForm.value, token)
      apiCall.subscribe(observer)
    } else {
      this.errorMessage = PrimaryUserMsg.INVALID_USERDETAILS_FORM_MSG;
    }
  }

  async deactivateUser() {

    this.errorMessage = '';
    const token = await lastValueFrom(this.authService.getAADToken(environment.tokenScopes));

    if (this.checkSourcingCompany()) {
      this.errorMessage = this.operationType === 'myProfile' ? PrimaryUserMsg.INCOMPLETE_PROFILE_ERROR_MSG : PrimaryUserMsg.INVALID_USERDETAILS_FORM_MSG;
      return
    }

    if (!this.userDetailsForm.valid || !this.isValidNumberForContractorType()) {
      this.errorMessage = PrimaryUserMsg.INVALID_USERDETAILS_FORM_MSG
      return;
    }

    this.isUpdating = true;
    const observer = {
      next: (x: IDefaultApiResponse) => {
        this.isUpdating = false;
        this.userIsActive = false;
        this.dialogService.OpenPopUpDialog("done", x.message)
      },
      error: (err: any) => {
        this.isUpdating = false;
        if (err.error.message !== undefined) {
          this.dialogService.OpenPopUpDialog("error",  err.error.message)
        } else {
          this.dialogService.OpenPopUpDialog("error", err.message)
        }
      }
    };

    let apiCall = this.primaryUserService.DeactivateUser(this.user?.provisioningId, token)
    apiCall.subscribe(observer)  
  }

  async createUser() {

    let accountEndDate = moment(this.userDetailsForm.value.accountEndDate);
    let newDate = moment().add(7, 'days'); 
    this.isInvalidAccountEndDate = accountEndDate.isBefore(newDate);

    if (this.userDetailsForm.get("personType")?.value === "JV Partner") {
      this.userDetailsForm.patchValue({ contractortype: 1 })
    }

    if (this.userDetailsForm.valid && !this.isInvalidAccountEndDate) {  

      this.isUpdating = true

      const token = await lastValueFrom(this.authService.getAADToken(environment.tokenScopes));

      const observer = {
        next: (x: IDefaultApiResponse) => {
          this.isUpdating = false;
          this.errorMessage = "";
          let message = x.message + ". Please allow up to 24 hours for the email to be created.";
          this.userDetailsForm.reset();
          this.dialogService.OpenPopUpDialog("done", message);
        },
        error: (err: any) => {
          this.isUpdating = false;
          if (err.error.message !== undefined) {
            this.dialogService.OpenPopUpDialog("error",  err.error.message)
          } else {
            this.dialogService.OpenPopUpDialog("error", err.message)
          }
        }
      };

      let apiCall = this.primaryUserService.CreateUser(this.userDetailsForm.value, token)
      apiCall.subscribe(observer)
      } else {
      this.errorMessage = PrimaryUserMsg.INVALID_USERDETAILS_FORM_MSG
      }
    }


  //#endregion

  //#region camp entity functions

  searchForCampEntity() {
    const dialogRef = this.dialog.open(DialogCampEntitySearchComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {

      let model = result as CampEntity;

      if (model.id !== undefined) {
        this.patchCampEntityData(model);
      }

    });
  }

  patchCampEntityData(data: CampEntity) {
    if (data.id === "") {
      this.userDetailsForm.patchValue({
        sourcingCompanyDisplay: "invalid data"
      });
    } else {
      this.userDetailsForm.patchValue({
        sourcingCompany: data.name,
        sourcingCompanyId: data.id,
        sourcingCompanyDisplay: `${data.name} [${data.id}]`,
      });
    }

    this.userDetailsForm.get("sourcingCompanyDisplay")?.markAsTouched();
  }

  async resolveCampEntityIdToName(id: string, token: any) {

    const observer = {
      next: (x: IDefaultApiResponse) => {
        let result = x.data as CampEntity[]

        if (result.length === 1) {
          this.patchCampEntityData(result[0]);
        } else {
          this.patchCampEntityData(new CampEntity());
        }
      },
      error: (err: any) => {

        if (err.error.statusCode !== 404) {
          // this will trigger on an unknown error (api unreachable etc)
          this.userDetailsForm.patchValue({sourcingCompanyDisplay: "validation error"})
          this.userDetailsForm.get("sourcingCompanyDisplay")?.markAsTouched();
        } else {
          // this will trigger when the api responds with a 404 - not found
          this.patchCampEntityData(new CampEntity());
        }
      }
    };

    let apiCall = this.campService.GetCampEntity(`id=${id}`, token);
    apiCall.subscribe(observer);
  }

  //#endregion

  //#region location functions

  searchForLocation() {

    const dialogRef = this.dialog.open(DialogLocationSearchComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {

      let model = result as ChevronLocation;

      if (model.id !== undefined) {
        this.patchLocationData(model);
      }

    });
  }

  async resolveLocationIdToName(id: string, token: any) {

    const observer = {
      next: (x: IDefaultApiResponse) => {
        let result = x.data as ChevronLocation[]

        if (result.length === 1) {
          this.patchLocationData(result[0]);
        } else {
          this.patchLocationData(new ChevronLocation());
        }
      },
      error: (err: any) => {

        if (err.error.statusCode !== 404) {
          // this will trigger on an unknown error (api unreachable etc)
          this.userDetailsForm.patchValue({locationDisplayName: "validation error"})
          this.userDetailsForm.get("locationDisplayName")?.markAsTouched();
        } else {
          // this will trigger when the api responds with a 404 - not found
          this.patchLocationData(new ChevronLocation());
        }
      }
    };

    let apiCall = this.locationService.GetLocation(`locationId=${id}`, token);
    if(apiCall)
      apiCall.subscribe(observer);
  }

  patchLocationData(data: ChevronLocation) {
    if (data.id === "") {
      this.userDetailsForm.patchValue({
        locationDisplayName: "invalid data"
      });
    } else {
      this.userDetailsForm.patchValue({
        locationAddress: data.street,
        locationBuildingName: data.buildingName,
        locationCity: data.city,
        locationCountry: data.country,
        locationCountryCode: data.countryCode,
        locationDisplayName: `${data.buildingName}, ${data.country} [${data.facilityCode}]`,
        locationFacilityCode: data.facilityCode,
        locationId: data.id,
        locationPostalCode: data.postalCode,
        locationPropertyId: data.propertyId,
        locationState: data.state, 
      });
    }

    this.userDetailsForm.get("locationDisplayName")?.markAsTouched();

  }
  //#endregion

  //#region validation error methods

  getLocationError() {
    const control = this.userDetailsForm.get("locationDisplayName");
    let returnMessage = ""

    if (control?.hasError("required")) {
      returnMessage = "This is a required field."
    }

    if (control?.hasError("pattern")) {
      
      if (control.value === "validation error") {
        returnMessage = "An unknown error occured when trying to validate the location id."
      }

      if (control.value === "invalid data") {
        returnMessage = "Invalid location data found, please select a new location."
      }
    }


    return returnMessage;
  }

  getCampError() {
    const control = this.userDetailsForm.get("sourcingCompanyDisplay");
    let returnMessage = ""

    if (control?.hasError("required")) {
      returnMessage = "This is a required field."
    }

    if (control?.hasError("pattern")) {
      
      if (control.value === "validation error") {
        returnMessage = "An unknown error occured when trying to validate the location id."
      }

      if (control.value === "invalid data") {
        returnMessage = "Invalid location data found, please select a new location."
      }
    }


    return returnMessage;
  }

  //#endregion

  //#region page helpers

  checkFieldViewState(fieldName: string) {

    switch (fieldName) {
      case "accountEndDate":
        if (this.userDetailsForm.controls['personType'].value === "Employee") {
          return false
        } else if (this.route === '/account/selfservice/me' || this.route === '/account/primaryaccounts/search') {
          return false
        } else {
          return true
        }
        case "Sonarqube":
          return false;
          // this is only here to stop sonarqube moaning
      default:
        return false;
    }
  }
 public passwordHide=()=>{
  this.hide= !this.hide;
 }

  getManageUserAuthorization(cvxClaimsPrincipal: ICvxClaimsPrincipal, user: IManageableUser)  {
    const manageUserAuth = this.primaryUserAuthService.CheckManageUserAuthorization(cvxClaimsPrincipal, user);
    this.isAllowedToManageUser = manageUserAuth.isAllowedToManageUser;
    this.isAllowedToEditName = manageUserAuth.isAllowedToEditName;
    this.isReadonly = !this.isAllowedToManageUser;
  }

  /**
   * 
   * @param {boolean} isSuperiorFields is for superior fields such as Sponsor, Supervisor and Approver Input Fields
   * @returns {boolean} 
   */
  isReadOnlyWorkerField() : boolean {
    return this.isEmployeeOnManageUser || this.isEmployeeOnMyProfile ;
  }

  isAllowedToEditNames(){
    return this.isAllowedToEditName || this.operationType === 'create';
  }

  clearCustomError() {
    if (!this.userIsActive) {
      this.isInvalidAccountEndDate = false;
    }
  }

  validateDate() {
    let accountEndDate = moment(this.userDetailsForm.value.accountEndDate);
    let newDate = moment().add(7, 'days'); 
    
    if (!this.userIsActive) {
      this.isInvalidAccountEndDate = accountEndDate.isBefore(newDate);
    }
  }

  isValidNumberForContractorType(): boolean {
    if(this.userDetailsForm.value.personType === 'Contractor') {
      switch(this.userDetailsForm.value.contractortype) {
        case 2: 
        case 3: 
        case 4: 
        case 5: 
          return true;
        default:
          return false;
      }
    }
    return true;
  }
  //#endregion
}
