import { environment } from "src/environments/environment";
import { PrincipalType } from "./enums/AppRoleAssignment/PrincipalType";

/**
 * API model for:
 *   POST: /api/applications/{{domain}}/bulkAssignAppRoles
 */
export class GraphApplicationRoleAssignmentModel {

    constructor(principalType: PrincipalType, principalId: string, resourceId: string, appRoleId: string){
        this.principalType = principalType;
        this.principalId = principalId;
        this.resourceId = resourceId;
        this.appRoleId = appRoleId;
    }

    principalType: PrincipalType;
    principalId: string;
    resourceId: string;
    appRoleId: string;
}

export class GraphApplicationRoleAssignmentDisplayModel extends GraphApplicationRoleAssignmentModel
{
    constructor(principalType: PrincipalType, principalId: string, resourceId: string, appRoleId: string, principalDisplayName: string, appRoleDisplayName: string, resourceDisplayName: string, domain: string = environment.idampUserDefaultDomain) 
    {
        super(principalType, principalId, resourceId, appRoleId);

        this.principalName = principalDisplayName?.length > 0 ? principalDisplayName : principalId;
        this.appRole = appRoleDisplayName?.length > 0 ? appRoleDisplayName : appRoleId;
        this.resourceApplication = resourceDisplayName?.length > 0 ? resourceDisplayName : resourceId;
        this.domain = domain;
    }

    // principal name
    public principalName: string;

    // appRole name
    public appRole: string;

    // resource App name
    public resourceApplication: string;

    // domain name
    public domain: string;

    // for displaying principal display name as a property named group
    public get group(): string 
    {
        return this.principalName
    }
}