import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";
import { PageLayout } from '@cvx/nextpage';
import { IGraphApplicationPermissions } from "src/app/models/graph/applications/GraphApplicationPermissions";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { GraphApplicationRoleAssignmentDisplayModel, GraphApplicationRoleAssignmentModel } from "src/app/models/graph/applications/GraphApplicationRoleAssignment";
import { GraphApplicationRoleAssignmentRequestModel } from "src/app/models/graph/applications/GraphApplicationRoleAssignmentRequest";
import { PrincipalType } from "src/app/models/graph/applications/enums/AppRoleAssignment/PrincipalType";
import { IGraphAppExposedPermissions } from "src/app/models/graph/applications/GraphAppExposedPermissions";
import { DialogDirectorySearchComponent } from "../../_shared/dialog-directory-search/dialog-directory-search.component";
import { GroupMemberType } from "src/app/models/directory/enums/GroupMemberType.enum";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { IDialogDirectoryData } from "src/app/models/components/IDialogDirectoryData";
import { DirectoryGroup } from "src/app/models/directory/groups/DirectoryGroup";
import { AadGroupSearchScenario } from "src/app/models/directory/enums/AadGroupSearchScenario.enum";
import { AppRoleAllowedMemberType } from "src/app/models/graph/applications/enums/AppRoleAllowedMemberType";

@Component({
    selector: 'app-assign-app-role',
    templateUrl: './assign-app-role.component.html',
    styleUrls: ['./assign-app-role.component.css']
})
export class AssignAppRoleComponent {

    private appRoleType: string = "microsoft.graph.appRole";

    PageLayout = PageLayout;
    submitErrorMessage = { message: '', errors: [] };
    isLoading: boolean = false;
    isCreating: boolean = false;
    isCompleted: boolean = false;

    appRoleAllowedMemberType: AppRoleAllowedMemberType = AppRoleAllowedMemberType.User

    clientApp?: GraphApplication;
    resourceApp?: IGraphApplicationPermissions;
    approleOptions: IGraphAppExposedPermissions[] = [];
    approleAssignmentsDisplayedColumns: string[] = ['appRole', 'group'];
    approleAssignments: GraphApplicationRoleAssignmentDisplayModel[] = [];
    approleAssignmentsErrorMessage: string;

    selectedAppRole: IGraphAppExposedPermissions | undefined = undefined;
    selectedGroup: DirectoryGroup | undefined = undefined;

    displayClientApp: any;
    displayResourceApp: any;

    submittedRequests: IWorkflowRequest[] = [];

    ownedApps: GraphApplication[] = [];

    constructor(
        private dialog: MatDialog,
        private graphApplicationService: GraphApplicationService
    ) { }

    clientAppSelected(data: IGraphApplicationPermissions) {

        this.clientApp = data.application;

        this.displayClientApp = {
            id: data.application.id,
            appId: data.application.appId,
            name: data.application.displayName,
            uri: data.application.identifierUris[0]
        };

        // check if the app has approles
        if (!this.clientApp.exposedPermissions.some(x => x.type == this.appRoleType)) {
            this.approleAssignmentsErrorMessage = `${this.clientApp.displayName} has no approles defined`
            this.approleOptions = [];
        }
        else {
            this.approleOptions = this.clientApp.exposedPermissions.filter(x => x.type == this.appRoleType).sort((role1, role2) => {
                if (role1.value > role2.value) {
                    return 1;
                }
                if (role1.value < role2.value) {
                    return -1;
                }
                return 0;
            });
        }
    }

    searchForGroup() {
        const dialogRef = this.dialog.open(DialogDirectorySearchComponent, {
            disableClose: true,
            autoFocus: true,
            maxWidth: 1000,
            width: '100%',
            data: {
                type: GroupMemberType.Group,
                domain: DirectoryDomain.Chevron,
                aadGroupSearchScenario: AadGroupSearchScenario.AppRoleAssignable,
                filterGroupRemoveNotAvailableAsGroupMember: false,
                filterGroupRemoveDynamicMembershipEnabled: false,
                filterGroupOnlyManagedByIdamp: false,
                filterGroupRemoveNonAzureGroups: true,
            } as IDialogDirectoryData
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                this.selectedGroup = result;
            }
        });
    }

    addNewAppRoleAssignment() {
        if (this.selectedGroup == undefined) {
            // show error message
            this.approleAssignmentsErrorMessage = "please select a group";
            return;
        }

        if (this.selectedAppRole == undefined) {
            // show error message
            this.approleAssignmentsErrorMessage = "please select an appRole";
            return;
        }

        if (this.approleAssignments.findIndex(o => o.appRoleId == this.selectedAppRole!.id && o.principalId == this.selectedGroup!.id) > -1) {
            // show error message
            this.approleAssignmentsErrorMessage = "the assignment has already been added";
            return;
        }

        // reset error message
        this.approleAssignmentsErrorMessage = "";

        this.approleAssignments = [...this.approleAssignments,
        new GraphApplicationRoleAssignmentDisplayModel(
            PrincipalType.Group,
            this.selectedGroup.id,
            this.clientApp!.servicePrincipalId,
            this.selectedAppRole.id,
            this.selectedGroup.displayName,
            this.selectedAppRole.value,
            this.displayResourceApp)
        ]
    }

    async onSubmit() {
        // clear error messages
        this.submitErrorMessage.message = '';
        this.submitErrorMessage.errors = [];

        if (this.clientApp !== undefined && this.approleAssignments.length > 0) {
            let payload = new GraphApplicationRoleAssignmentRequestModel(DirectoryDomain.Chevron, this.approleAssignments as GraphApplicationRoleAssignmentModel[]);

            const observer = {
                next: (x: IGenericApiResponseWithWorkflowRequest<any>) => {
                    this.submittedRequests = x.requests;
                },
                error: (err: any) => {
                    this.submitErrorMessage.message = err.statusText;
                    this.submitErrorMessage.errors = err?.error?.errors ?? [];
                    this.isCreating = false;
                },
                complete: () => {
                    this.isCreating = false;
                    this.isCompleted = true;
                }
            };

            this.isCreating = true;
            let createCall = this.graphApplicationService.CreateApplicationRoleAssignment(payload);
            createCall.subscribe(observer);
        }
    }

    removeAssignment(assignment: GraphApplicationRoleAssignmentDisplayModel) {
        this.approleAssignments = this.approleAssignments.filter(s => s.principalId != assignment.principalId || s.appRoleId != assignment.appRoleId)
    }

    resetForm() {
        this.clientApp = undefined;
        this.displayClientApp = undefined;
        this.resourceApp = undefined;
        this.displayResourceApp = undefined;
        this.approleAssignments = [];
        this.approleAssignmentsErrorMessage = "";
        this.approleOptions = [];
        this.selectedGroup = undefined;
        this.selectedAppRole = undefined;
        this.submitErrorMessage.message = '';
        this.submitErrorMessage.errors = [];
    }
}