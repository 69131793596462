import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CalAngularService } from '@cvx/cal-angular';
import { lastValueFrom } from 'rxjs';
import { IDefaultApiResponse } from 'src/app/models/defaultApiResponse';
import { PrimaryUser } from 'src/app/models/primaryUser';
import { PrimaryUserService } from 'src/app/services/primaryUser/primaryUser.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-primary-user-search',
  templateUrl: './primary-user-search.component.html',
  styleUrls: ['./primary-user-search.component.css']
})
export class PrimaryUserSearchComponent {

  @ViewChild('searchResultsPaginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Input() inactiveSearch: boolean = false;
  @Input() personType: string = "";
  @Input() isDialog: boolean = false;
  @Input() fromManageUser: boolean = false;
  @Output() userSelected = new EventEmitter<{ selectedUser: PrimaryUser }>();
  @Output() searchCancelled = new EventEmitter<{ searchCancelled: boolean }>();

  dataSource = new MatTableDataSource<PrimaryUser>();
  displayedColumns: string[] = ['displayName', 'email', 'city', 'provisioningId'];
  SearchResults: PrimaryUser[] = []
  screenMessage: string = ""
  IsSearching: boolean = false;

  // form elements
  email = new UntypedFormControl('', [Validators.email]);
  firstName = new UntypedFormControl('', [
    Validators.minLength(2),
    Validators.maxLength(40),
    Validators.pattern("^[a-zA-Z-' ]*$")
  ]);
  lastName = new UntypedFormControl('', [
    Validators.minLength(2),
    Validators.maxLength(40),
    Validators.pattern("^[a-zA-Z-' ]*$")
  ]);
  provisioningId = new UntypedFormControl('', [Validators.pattern("^[0-9]*$")]);
  city = new UntypedFormControl('', [Validators.pattern("^[a-zA-Z ]*$")]);

  constructor(private primaryUserService: PrimaryUserService, private cdr: ChangeDetectorRef, private authService: CalAngularService) { }

  ngOnInit(){
    //add extra column when Manage Primary User is called
    if(this.fromManageUser) {
      this.displayedColumns.push('isActive');
    }
  }

  getErrorMessage(controlName: string) {
    let selectedControl: UntypedFormControl;

    if (controlName === "firstName") {
      selectedControl = this.firstName;
    } else {
      selectedControl = this.lastName;
    }

    if (selectedControl.hasError('minlength')) {
      return 'Name must be at least 2 characters long.';
    }

    if (selectedControl.hasError('maxlength')) {
      return 'Name cannot exceeed 40 characters long.';
    }

    return 'Name may only contain letters, spaces and !\'.';
  }

  search() {
    this.screenMessage = ""
    if (this.email.value === "" && this.firstName.value === "" &&
      this.lastName.value === "" && this.provisioningId.value === "" &&
      this.city.value === "") {

      this.screenMessage = "You have not entered any search parameters."

    } else if (this.email.invalid || this.firstName.invalid ||
      this.lastName.invalid || this.provisioningId.invalid ||
      this.city.invalid) {

      this.screenMessage = "You have entered invalid search parameters."
    } else {
      this.searchUsers();
    }
  }

  async searchUsers() {
    this.IsSearching = true;
    let searchParams = [];

    const token = await lastValueFrom(this.authService.getAADToken(environment.tokenScopes));

    if (this.email.value !== "" && this.email.value !== null) {
      searchParams.push(`email=${this.email.value}`);
    }

    if (this.firstName.value !== "" && this.firstName.value !== null) {
      searchParams.push(`firstname=${this.firstName.value}`);
    }

    if (this.lastName.value !== "" && this.lastName.value !== null) {
      searchParams.push(`lastname=${this.lastName.value}`);
    }

    if (this.provisioningId.value !== "" && this.provisioningId.value !== null) {
      searchParams.push(`provisioningid=${this.provisioningId.value}`);
    }

    if (this.city.value !== "" && this.city.value !== null) {
      searchParams.push(`city=${this.city.value}`);
    }

    if (this.personType !== "") {
      searchParams.push(`persontype=${this.personType}`);
    }

    this.getUsers(searchParams, token);
  }

  getUsers(searchParams: string[], token: any) {
    const observer = {
      next: (x: IDefaultApiResponse) => this.SearchResults = x.data as PrimaryUser[],
      error: (err: any) => {
        if (err.error.message !== undefined) {
          this.screenMessage = err.error.message
        } else {
          this.screenMessage = err.message
        }
        this.IsSearching = false;
      },
      complete: () => {
        this.dataSource.data = this.SearchResults
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.IsSearching = false;
      }
    };

    let searchtype = "active"

    if (this.inactiveSearch) {
      searchtype = "inactive";
    }

    //removed search type when page is from Manage Primary User to display all the inactive users on manage primary user
    let apiCall = this.fromManageUser ? this.primaryUserService.GetPrimaryUsers(searchParams.join("&"), token) : this.primaryUserService.GetPrimaryUsers(searchParams.join("&"), token, searchtype);    
    if(apiCall)
      apiCall.subscribe(observer);
  }
  back() {
    this.SearchResults = [];
  }

  reset() {
    this.email.reset()
    this.firstName.reset()
    this.lastName.reset()
    this.provisioningId.reset()
    this.city.reset()
  }

  selectRow(selected: PrimaryUser) {
    this.userSelected.emit({ selectedUser: selected })
  }
}
