import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { PrimaryUser } from '../models/primaryUser';
import { NameValidators } from '../validators/nameValidators';
import { Subject } from 'rxjs';
import { UserType } from '../models/directory/enums/UserType.enum';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  private validateUpdateUserForms: Subject<boolean> = new Subject<boolean>();

  public getStewardSponsorTooltipRule(userType: UserType): string {
    let tooltip = '';
    switch (userType) {
      case UserType.Guest:
        tooltip = `only employees and authorized users can be a ${this.getStewardSponsorLabel(userType)}`;
        break;
      default:
        tooltip = `only primary users are allowed to be ${this.getStewardSponsorLabel(userType)}s`;
        break;
    }
    return tooltip;
  }
  public getStewardSponsorLabel(userType: UserType): string {
    let label = '';
    switch (userType) {
      case UserType.Guest:
        label = "sponsor";
        break;
      default:
        label = "steward";
        break;
    }
    return label;
  }
  public setIsValidateUpdateUserForms(isValidate: boolean) {
    this.validateUpdateUserForms.next(isValidate);
  }

  public getIsValidateUpdateUserForms() {
    return this.validateUpdateUserForms;
  }

  public validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  } 

  public GetPrimaryUserForm() {

    let form = new UntypedFormGroup({
      accountEndDate: new UntypedFormControl('', []),
      approver: new UntypedFormControl('', [Validators.required]),
      approverDisplayName: new UntypedFormControl('', [Validators.required]),
      authorizationCode: new UntypedFormControl('', []),
      cai: new UntypedFormControl('', []),
      contractortype: new UntypedFormControl('', [Validators.required]),
      ctnNumber: new UntypedFormControl('', [Validators.minLength(8), Validators.maxLength(8)]),
      ctnNumberAlt: new UntypedFormControl('', [Validators.minLength(8), Validators.maxLength(8)]),
      displayName: new UntypedFormControl('', []),
      dataSource: new UntypedFormControl('', []),
      email: new UntypedFormControl('', []),
      firstname: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(40), Validators.pattern("^[a-zA-Z-', ]*$"), NameValidators.allCaps]),
      hrBillingCompany: new UntypedFormControl('', []),
      hrCostCenter: new UntypedFormControl('', []),
      hrEmployeeRU: new UntypedFormControl('', []),
      hrEmployeeRULongDescription: new UntypedFormControl('', []),
      hrEmployeeRUShortDescription: new UntypedFormControl('', []),
      hrGUID: new UntypedFormControl('', []),
      hrName: new UntypedFormControl('', []),
      hrJobTitle: new UntypedFormControl('', []),
      hrLevel1code: new UntypedFormControl('', []),
      hrLevel1LongDescription: new UntypedFormControl('', []),
      hrLevel1ShortDescription: new UntypedFormControl('', []),
      hrLevel2code: new UntypedFormControl('', []),
      hrLevel2LongDescription: new UntypedFormControl('', []),
      hrLevel2ShortDescription: new UntypedFormControl('', []),
      hrLevel3code: new UntypedFormControl('', []),
      hrLevel3LongDescription: new UntypedFormControl('', []),
      hrLevel3ShortDescription: new UntypedFormControl('', []),
      hrLevel4code: new UntypedFormControl('', []),
      hrLevel4LongDescription: new UntypedFormControl('', []),
      hrLevel4ShortDescription: new UntypedFormControl('', []),
      hrLevel5code: new UntypedFormControl('', []),
      hrLevel5LongDescription: new UntypedFormControl('', []),
      hrLevel5ShortDescription: new UntypedFormControl('', []),
      hrPersonnelNumber: new UntypedFormControl('', []),
      jobtitle: new UntypedFormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(40)]),
      lastname: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(40), Validators.pattern("^[a-zA-Z-', ]*$"), NameValidators.allCaps]),
      locationAddress: new UntypedFormControl('', [Validators.required]),
      locationBuildingName: new UntypedFormControl('', [Validators.required]),
      locationCity: new UntypedFormControl('', [Validators.required]),
      locationCountry: new UntypedFormControl('', [Validators.required]),
      locationCountryCode: new UntypedFormControl('', []),
      locationDisplayName: new UntypedFormControl('', [Validators.required, Validators.pattern("^(?!.*(invalid|error)).*")]),
      locationFacilityCode: new UntypedFormControl('', [Validators.required]),
      locationId: new UntypedFormControl('', [Validators.required]),
      locationPostalCode: new UntypedFormControl('',),
      locationPropertyId: new UntypedFormControl('', [Validators.required]),
      locationRoom: new UntypedFormControl('', []),
      locationState: new UntypedFormControl('', []),
      mobileNumber: new UntypedFormControl('', []),
      personalTelephoneNumber: new UntypedFormControl('', []),
      personalTelephoneNumberAlt: new UntypedFormControl('', []),
      personalEmail: new UntypedFormControl('', [Validators.email]),
      personType: new UntypedFormControl('Employee', [Validators.required]),
      provisioningId: new UntypedFormControl('', []),
      sourcingCompany: new UntypedFormControl('', []),
      sourcingCompanyId: new UntypedFormControl('', []),
      sourcingCompanyDisplay: new UntypedFormControl('', [Validators.required]),
      sponsor: new UntypedFormControl('', [Validators.required]),
      sponsorDisplayName: new UntypedFormControl('', [Validators.required]),
      supervisor: new UntypedFormControl('', [Validators.required]),
      supervisorDisplayName: new UntypedFormControl('', [Validators.required]),
      telephoneNumber: new UntypedFormControl('', []),
      telephoneNumberAlt: new UntypedFormControl('', []),
      workcompany: new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9]{4}$")])
    });

    return form;
  }

  public PatchPrimaryUserFormWithUserObject(form: UntypedFormGroup, user: PrimaryUser) {

    form.patchValue({
      accountEndDate: user?.accountEndDate,
      approver: user?.approver,
      authorizationCode: user?.authorizationCode,
      cai: user?.cai,
      contractortype: user?.contractorType,
      ctnNumber: user?.ctnNumber,
      ctnNumberAlt: user?.ctnNumberAlt,
      dataSource: user?.dataSource,
      displayName: user?.displayName,
      email: user?.email,
      firstname: user?.firstName,
      hrBillingCompany: user?.hrBillingCompany,
      hrCostCenter: user?.hrCostCenter,
      hrEmployeeRU: user?.hrEmployeeRU,
      hrEmployeeRULongDescription: user?.hrEmployeeRULongDescription,
      hrEmployeeRUShortDescription: user?.hrEmployeeRUShortDescription,
      hrGUID: user?.hrGUID,
      hrName: [user?.hrGivenName, user?.hrMiddleName, user?.hrSurname].filter(Boolean).join(" "),
      hrJobTitle: user?.hrJobTitle,
      hrLevel1code: user?.hrLevel1code,
      hrLevel1LongDescription: user?.hrLevel1LongDescription,
      hrLevel1ShortDescription: user?.hrLevel1ShortDescription,
      hrLevel2code: user?.hrLevel2code,
      hrLevel2LongDescription: user?.hrLevel2LongDescription,
      hrLevel2ShortDescription: user?.hrLevel2ShortDescription,
      hrLevel3code: user?.hrLevel3code,
      hrLevel3LongDescription: user?.hrLevel3LongDescription,
      hrLevel3ShortDescription: user?.hrLevel3ShortDescription,
      hrLevel4code: user?.hrLevel4code,
      hrLevel4LongDescription: user?.hrLevel4LongDescription,
      hrLevel4ShortDescription: user?.hrLevel4ShortDescription,
      hrLevel5code: user?.hrLevel5code,
      hrLevel5LongDescription: user?.hrLevel5LongDescription,
      hrLevel5ShortDescription: user?.hrLevel5ShortDescription,
      hrPersonnelNumber: user?.hrPersonnelNumber,
      jobtitle: user?.jobTitle,
      lastname: user?.lastName,
      locationRoom: user?.locationRoom,
      mobileNumber: user?.mobileNumber,
      personalTelephoneNumber: user?.personalTelephoneNumber,
      personalTelephoneNumberAlt: user?.personalTelephoneNumberAlt,
      personalEmail: user?.personalEmail,
      personType: user?.personType,
      provisioningId: user?.provisioningId,
      sourcingCompany: user?.sourcingCompany,
      sourcingCompanyId: user?.sourcingCompanyId,
      sourcingCompanyDisplay: `${user?.sourcingCompany} [${user?.sourcingCompanyId}]`,
      sponsor: user?.sponsor,
      supervisor: user?.supervisor,
      telephoneNumber: user?.telephoneNumber,
      telephoneNumberAlt: user?.telephoneNumberAlt,
      workcompany: user?.workCompany,
    });

  }

  public MarkPrimaryUserFormAsTouched(form: UntypedFormGroup) {
    for (let control in form.controls) {
      form.controls[control].markAsTouched();
    }
  }

  public addCreateUserValidator(form: UntypedFormGroup) {
    const personTypeControl = form.get('personType') ;
    const accEndDateControl = form.get('accountEndDate');

      personTypeControl?.setValidators([(control: AbstractControl): ValidationErrors | null => {
        if ((control.value === 'Employee'|| !control.value)) { //check if the default value is employee and or if value is emplty
          return { required: true }
        }
        return null; //remove the required validation since the value is already not Employee or not empty
      }]);
      accEndDateControl?.setValidators([Validators.required])
  
    personTypeControl?.updateValueAndValidity();
    accEndDateControl?.updateValueAndValidity();
  }

}
