import { Component } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { GraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";
import { PageLayout } from '@cvx/nextpage';
import { IGraphApplicationPermissions } from "src/app/models/graph/applications/GraphApplicationPermissions";
import { GraphAppPermissionRequestModel } from 'src/app/models/graph/applications/GraphAppPermissionRequest';
import { GraphConstants } from "src/app/models/graph/Graph.constants";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";

@Component({
    selector: 'request-permissions-application',
    templateUrl: './request-permissions.html',
    styleUrls: ['./request-permissions.css']
})

export class RequestPermissionsComponent {

    PageLayout = PageLayout;
    submitErrorMessage = { message: '', errors: [] };
    isLoading: boolean = false;
    isCreating: boolean = false;
    isCompleted: boolean = false;
    panelOpenState = false;
    createdResponse: IGenericApiResponseWithWorkflowRequest<any>;
    searchFoundNoExposedPermissions: boolean = false;

    ownedApps: GraphApplication[] = [];

    constructor(
        private graphApplicationService: GraphApplicationService
    ) { }

    clientApp?: GraphApplication;
    resourceApp?: IGraphApplicationPermissions;

    displayClientApp: any;
    displayResourceApp: any;

    justification = new UntypedFormControl('', { validators: [Validators.required] });

    clientAppSelected(data: IGraphApplicationPermissions) {
        this.clientApp = data.application;

        this.displayClientApp = {
            id: data.application.id,
            appId: data.application.appId,
            name: data.application.displayName,
            uri: data.application.identifierUris[0]
        };
    }

    resourceAppSelected(data: IGraphApplicationPermissions) {
        this.resourceApp = data;
        if (data.application.id) {
            this.displayResourceApp = {
                id: data.application.id,
                appId: data.application.appId,
                name: data.application.displayName,
                uri: data.application.identifierUris[0],
                owners: data.application.owners.map(item => item.displayName),
                permissions: data.permissions.map(item => {
                    let type = GraphConstants.AppPermissionsDisplayValues.get(item.type) ?? 'unknown';
                    return `${item.value} (${type})`;
                })
            };
        }
        else {
            this.displayResourceApp = {
                name: data.application.displayName,
                permissions: data.permissions.map(item => {
                    let type = GraphConstants.AppPermissionsDisplayValues.get(item.type) ?? 'unknown';
                    return `${item.value} (${type})`;
                })
            }
        }
    }

    async onSubmit() {

        // clear error messages
        this.submitErrorMessage.message = '';
        this.submitErrorMessage.errors = [];

        if (this.clientApp !== undefined && this.resourceApp !== undefined && this.justification.valid) {
            let clientId = this.clientApp.id;
            let payload = new GraphAppPermissionRequestModel(
                this.resourceApp.application.servicePrincipalId,
                this.resourceApp.permissions.filter(item => item.type === GraphConstants.PermissionsScope).map(item => item.id),
                this.resourceApp.permissions.filter(item => item.type === GraphConstants.PermissionsApp).map(item => item.id),
                this.justification.value
            );

            const observer = {
                next: (x: IGenericApiResponseWithWorkflowRequest<any>) => {
                    this.createdResponse = x;
                },
                error: (err: any) => {
                    this.submitErrorMessage.message = err.statusText;
                    this.submitErrorMessage.errors = err?.error?.errors ?? [];
                    this.isCreating = false;
                },
                complete: () => {
                    this.isCreating = false;
                    this.isCompleted = true;
                }
            };

            this.isCreating = true;
            let createCall = this.graphApplicationService.CreateApplicationPermissionRequest(clientId, payload);
            createCall.subscribe(observer);
        }
        else if (this.justification.invalid) {
            this.submitErrorMessage.message = 'a business justification is required';
        }
    }

    noExposedPermissionsFound(event: boolean) {
        this.searchFoundNoExposedPermissions = event;
    }

    resetForm() {
        this.clientApp = undefined;
        this.displayClientApp = undefined;
        this.resourceApp = undefined;
        this.displayResourceApp = undefined;
        this.justification.setValue('');
    }

}