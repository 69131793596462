<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row">
            @if (!isCompleted){
            <div class="col col-12">
                <form class="branded">
                    <h1>request app permissions</h1>
                    <mat-error></mat-error>

                    <fieldset class="pb-3">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>client application</strong></p>
                        </legend>
                        @if (clientApp === undefined){
                        <app-search (searchFoundNoExposedPermissions)="noExposedPermissionsFound($event)"
                            [ownedApps]="true" [searchPermissions]="false" [showTitle]="true"
                            (appSelected)="clientAppSelected($event)" />
                        } @else if (displayClientApp !== undefined){
                        <display-form [displayObject]="displayClientApp" />
                        }
                    </fieldset>

                    <fieldset class="pb-3">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>resource application</strong></p>
                        </legend>
                        @if (resourceApp === undefined){
                        <app-search [sortable]="true" [includeNumberOfExposedPermissions]="true" [ownedApps]="false"
                            [searchPermissions]="true" [servicePrincipals]="true"
                            (appSelected)="resourceAppSelected($event)"
                            (searchFoundNoExposedPermissions)="noExposedPermissionsFound($event)" />
                        }@else if (displayResourceApp !== undefined){
                        <display-form [displayObject]="displayResourceApp" />
                        }
                    </fieldset>

                    @if (searchFoundNoExposedPermissions){
                    <div class="row pb-4">
                        <div class="col-12">
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        can't find a non-chevron application permission
                                    </mat-panel-title>
                                    <mat-panel-description>
                                        expand to see which ones are requestable from IdAMP
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <app-requestable-permissions></app-requestable-permissions>
                            </mat-expansion-panel>
                        </div>
                    </div>
                    }
                    <mat-divider></mat-divider>
                    <fieldset class="pb-3">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>justification</strong></p>
                        </legend>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100"
                                    matTooltip="Justification for permissions request" subscriptSizing="dynamic">
                                    <mat-label>justification</mat-label>
                                    <input matInput [formControl]="justification">
                                    <mat-error>Justification is required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </fieldset>

                    <mat-divider></mat-divider>

                    <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
                        @if (isCreating){
                        <mat-spinner [diameter]="30"
                            style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                        }@else {
                        @if (clientApp !== undefined && resourceApp !== undefined){
                        <div class="btn-group mr-2" role="group">
                            <button class="button" type="submit" (click)="onSubmit()">submit</button>
                        </div>
                        }
                        <div class="btn-group mr-2" role="group">
                            <button class="button" type="button" (click)="resetForm()">reset</button>
                        </div>
                        }
                    </div>

                    <display-http-error [message]="submitErrorMessage.message"
                        [errors]="submitErrorMessage.errors"></display-http-error>

                </form>
            </div>
            }@else {
            <div class="col col-12">
                <a class="close-button" [routerLink]="['/applications/request-permissions']"><span
                        class="icon-remove close-button"></span></a>
                <h1>request submitted</h1>
                <display-requests [requests]="createdResponse.requests"></display-requests>
            </div>
            }
        </div>
    </section>
</main>