<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row" *ngIf="submittedRequests.length === 0">
            <div class="col col-12">
                <h1>assign approles</h1>

                <form class="branded" *ngIf="!isCompleted">

                    <fieldset class="pb-3">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>application</strong></p>
                        </legend>
                        <app-search [ownedApps]="true" [searchPermissions]="false"
                            [appRoleAllowedMemberType]="appRoleAllowedMemberType"
                            (appSelected)="clientAppSelected($event)" *ngIf="clientApp === undefined">
                        </app-search>

                        <display-form [displayObject]="displayClientApp"
                            *ngIf="displayClientApp !== undefined"></display-form>
                    </fieldset>

                    <fieldset class="pb-4" *ngIf="approleOptions.length > 0">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>define assignment</strong></p>
                        </legend>
                        <div class="container">
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-6">
                                    <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
                                        <mat-label>app role</mat-label>
                                        <mat-select (selectionChange)="selectedAppRole = $event.value">
                                            <mat-option matTooltip="{{appRole.description}}"
                                                *ngFor="let appRole of approleOptions" [value]="appRole">{{
                                                appRole.value }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-12 col-md-6 col-lg-6">
                                    <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
                                        <mat-label>group</mat-label>
                                        <input matInput [readonly]="true" [value]="selectedGroup?.displayName"
                                            matTooltip="Only azure secruity groups are allowed for assignment"
                                            (click)="searchForGroup()">
                                        <button mat-icon-button matSuffix (click)="searchForGroup()">
                                            <mat-icon>search</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-12 col-lg-12">
                                    <div style="margin-bottom: 10px;">
                                        <button [disabled]="selectedAppRole == undefined || selectedGroup == undefined"
                                            mat-flat-button color="primary" (click)="addNewAppRoleAssignment()"
                                            class="add-button" type="button">
                                            <mat-icon>add</mat-icon> assignment
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <display-http-error [message]="approleAssignmentsErrorMessage" [errors]="[]"></display-http-error>

                    <fieldset class="pb-4" *ngIf="approleAssignments.length > 0">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>new assignments</strong></p>
                        </legend>
                        <generic-search-results [showHeader]="false" [records]="approleAssignments"
                            [showCounter]="false" [title]="''" [caption]="'assignments to make'"
                            [displayedColumns]="approleAssignmentsDisplayedColumns" [selectableRecords]="false"
                            [deletableRecords]="true" [showPagination]="false" [allowLocalFilter]="false"
                            (recordDeleted)="removeAssignment($event)">
                        </generic-search-results>
                    </fieldset>

                    <mat-divider></mat-divider>

                    <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar" *ngIf="!isLoading">
                        <mat-spinner [diameter]="30" *ngIf="isCreating"
                            style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                        <div class="btn-group mr-2" role="group"
                            *ngIf="!isCreating && clientApp !== undefined && submittedRequests.length == 0 && approleAssignments.length > 0">
                            <button class="button" type="submit" (click)="onSubmit()">submit</button>
                        </div>
                        <div class="btn-group mr-2" role="group" *ngIf="!isCreating && clientApp != undefined">
                            <button class="button" type="button" (click)="resetForm()">reset</button>
                        </div>
                    </div>


                    <display-http-error [message]="submitErrorMessage.message"
                        [errors]="submitErrorMessage.errors"></display-http-error>

                </form>
            </div>
        </div>
        <div class="row" *ngIf="submittedRequests.length > 0">
            <div class="col col-12">
                <a class="close-button" [routerLink]="['/applications/assign-approles']"><span
                        class="icon-remove close-button"></span></a>
                <h1>request submitted</h1>
                <display-http-error [message]="submitErrorMessage.message"
                    [errors]="submitErrorMessage.errors"></display-http-error>
                <display-requests [requests]="submittedRequests"></display-requests>
            </div>
        </div>
    </section>
</main>