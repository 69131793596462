import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { DirectoryGroup } from "src/app/models/directory/groups/DirectoryGroup";
import { GraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { GraphApplicationRoleAssignmentDisplayModel } from "src/app/models/graph/applications/GraphApplicationRoleAssignment";
import { GraphApplicationRoleAssignmentRequestModel } from "src/app/models/graph/applications/GraphApplicationRoleAssignmentRequest";
import { AppRoleAllowedMemberType } from "src/app/models/graph/applications/enums/AppRoleAllowedMemberType";
import { PrincipalType } from "src/app/models/graph/applications/enums/AppRoleAssignment/PrincipalType";
import { DirectoryGroupService } from "src/app/services/directory/DirectoryGroup.service";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";

@Component({
    selector: 'request-details-app-role-assignment',
    templateUrl: './request-details-app-role-assignment.component.html'
})

export class RequestDetailsAppRoleAssignment implements OnInit {

    @Input() requestData: GraphApplicationRoleAssignmentRequestModel;

    @Output() doneLoading = new EventEmitter<boolean>();

    approleAssignments: GraphApplicationRoleAssignmentDisplayModel[] = []
    displayColumns: string[] = ['domain', 'group', 'appRole', 'resourceApplication'];

    groupPrincipals: DirectoryGroup[] = [];
    groupPrincipalsLoaded: boolean = false;

    resourceApps: GraphApplication[] = [];
    resourceAppsLoaded: boolean = false;

    constructor(private graphAppService: GraphApplicationService, private groupService: DirectoryGroupService) {
    }

    ngOnInit() {
        this.loadGroupPrincipals();
        this.loadResourceApps();
    }

    async loadGroupPrincipals() {
        const groupAssignments = this.requestData.assignments.filter(x => x.principalType.toLowerCase() === `${PrincipalType.Group.toLocaleLowerCase()}`)

        if (groupAssignments.length == 0) {
            this.groupPrincipalsLoaded = true;
        }
        else {
            groupAssignments.forEach(x => {
                    this.groupService.GetByIdAsync(x.principalId, this.requestData.domain).then((g: IGenericApiResponse<DirectoryGroup>) => {
                    this.groupPrincipals = [...this.groupPrincipals, g.data];
                }).catch(ex => {
                    console.log(ex)
                }).finally(() => {
                    this.groupPrincipalsLoaded = true;
                    this.isDone()
                })
            })
        }
    }

    async loadResourceApps() {
        // get unique resources ids to lookup
        const uniqueResourceIdAssignments = this.requestData.assignments.filter((assignment, i, arr) => arr.findIndex(t => t.resourceId == assignment.resourceId) === i)

        if (uniqueResourceIdAssignments.length == 0) {
            this.resourceAppsLoaded = true;
        }
        else {
            uniqueResourceIdAssignments.forEach(x => {
                    this.graphAppService.GetServicePrincipalAsync(x.resourceId, AppRoleAllowedMemberType.All).then((x: IGenericApiResponse<GraphApplication>) => {
                    this.resourceApps = [...this.resourceApps, x.data];
                }).catch(ex => {
                    console.log(ex)
                }).finally(() => {
                    this.resourceAppsLoaded = true;
                    this.isDone()
                })
            })
        }
    }

    isDone() {
        if (this.groupPrincipalsLoaded && this.resourceAppsLoaded) {

            let dispalyAssignments : GraphApplicationRoleAssignmentDisplayModel[] = [];
            this.requestData.assignments.forEach(x => {
                const resourceApp = this.resourceApps?.find(r => r.servicePrincipalId == x.resourceId);
                let resourceDisplayName = resourceApp?.displayName ?? x.resourceId;
                let appRoleDisplayName = resourceApp?.exposedPermissions?.find(p => p.id == x.appRoleId)?.value ?? x.appRoleId
                let principalDisplayname = (() => {
                    switch (`${x.principalType.toLocaleLowerCase()}`) {
                        case `${PrincipalType.Group.toLocaleLowerCase()}`:
                            return this.groupPrincipals?.find(g => g.id == x.principalId)?.displayName ?? x.principalId
                        default:
                            return x.principalId
                    }
                })();
                dispalyAssignments.push(new GraphApplicationRoleAssignmentDisplayModel(x.principalType, 
                    x.principalId, x.resourceId, x.appRoleId, 
                    principalDisplayname, appRoleDisplayName, resourceDisplayName, this.requestData.domain))
            })

            this.approleAssignments = [...dispalyAssignments]
            this.doneLoading.emit(true);
        }
    }
}