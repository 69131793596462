<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row" *ngIf="submittedRequests.length === 0">
            <div class="col col-12">
                <h1>revoke permissions</h1>

                <form class="branded" *ngIf="!isCompleted">
                    <fieldset class="pb-4">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>permission role</strong></p>
                        </legend>
                        <mat-button-toggle-group class="mat-button-toggle-group" name="permissionRole" aria-label="Type"
                            [value]="role">
                            <mat-button-toggle class="mat-button-toggle" *ngFor="let roleType of roleTypes" [value]="roleType"
                                (click)="typeChange(roleType)">{{ roleType }}</mat-button-toggle>
                        </mat-button-toggle-group>

                        <div class="client-resource-description">
                            @if (role == "Client")
                            {
                                any permissions granted to <span class="font-weight-bold">the selected application</span> will be displayed
                            }
                            @else
                            {
                                any permissions associated with <span class="font-weight-bold">the selected application</span> that have been granted to <span class="font-italic">other principals</span> will be displayed
                                <div class="text-warning">this may take up to a minute to retrieve</div>
                            }
                        </div>
                    </fieldset>
                    <fieldset class="pb-3">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>application</strong></p>
                        </legend>
                        <app-search [ownedApps]="true" [searchPermissions]="false"
                            [appRoleAllowedMemberType]="appRoleAllowedMemberType"
                            (appSelected)="clientAppSelected($event)" *ngIf="clientApp === undefined">
                        </app-search>

                        <display-form [displayObject]="displayClientApp"
                            *ngIf="displayClientApp !== undefined"></display-form>
                    </fieldset>
                </form>

                <fieldset class="pb-4" *ngIf="displayClientApp !== undefined">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                        <p><strong>revokeable permissions</strong></p>
                    </legend>                    
                    <div *ngIf="!isLoadingPermissions && revokeablePermissionsDataSource.data.length == 0">
                        no permissions revokeable permissions were found
                    </div>
                    <div *ngIf="isLoadingPermissions">
                        <div class="row justify-content-center col-sm-12">
                            <p>searching for permissions...</p>                               
                        </div>
                        <div class="row justify-content-center col-sm-12">
                            <mat-spinner></mat-spinner>                               
                        </div>                                
                    </div>
                    <div>
                        <form class="branded">
                            <filter-input (onKeyUp)="doFilterPermissions($event)" 
                                *ngIf="!isLoadingPermissions && revokeablePermissionsDataSource.data.length > 0"></filter-input>
                            
                            <table id="revokeablePermissions" 
                            [hidden]="isLoadingPermissions || revokeablePermissionsDataSource.data.length == 0"
                            mat-table 
                            [dataSource]="revokeablePermissionsDataSource" 
                            matSort
                            matSortActive="principalName" 
                            matSortDirection="asc"
                            aria-describedby="revokeable permissions" >
                        
                                <caption>permissions that can be revoked</caption>
                        
                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let row">
                                        <input
                                            MatCheckbox
                                            type="checkbox"
                                            name="formcheckbox"
                                            (click)="$event.stopPropagation()" 
                                            (change)="selectPermission(row)"
                                            [checked]="selection.isSelected(row)"
                                            />
                                    </td>
                                </ng-container>
                        
                                <ng-container matColumnDef="principalName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> principal name </th>
                                    <td mat-cell *matCellDef="let permission">{{ permission.principalName }}</td>
                                </ng-container>
                        
                                <ng-container matColumnDef="principalType">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> principal type </th>
                                    <td mat-cell *matCellDef="let permission">{{ permission.principalType }}</td>
                                </ng-container>
                        
                                <ng-container matColumnDef="permissionType">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> permission type </th>
                                    <td mat-cell *matCellDef="let permission">
                                        {{ permission.permissionType }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="permissionValue">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> permission value </th>
                                    <td mat-cell *matCellDef="let permission">
                                        {{ permission.permissionValue }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="resourceName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> resource name </th>
                                    <td mat-cell *matCellDef="let permission">
                                        {{ permission.resourceName }}
                                    </td>
                                </ng-container>
                        
                                <tr mat-header-row *matHeaderRowDef="selectPermissionsDisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: selectPermissionsDisplayedColumns;"></tr>
                        
                            </table>
                            <mat-paginator *ngIf="!isLoadingPermissions" [length]="revokeablePermissionsDataSource.data.length" [pageSizeOptions]="[10, 20, 30, 40]" showFirstLastButtons></mat-paginator>
                        </form>
                    </div>
                </fieldset>


                <display-http-error [message]="getPermissionsErrorMessage" [errors]="[]"></display-http-error>

                <fieldset class="pb-4" *ngIf="!isLoadingPermissions && revokeablePermissionsDataSource.data.length > 0">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white"><p><strong>selected permissions to revoke</strong></p></legend>
                    <generic-search-results 
                    [showHeader]="false" 
                    [records]="selection.selected" 
                    [showCounter]="false"
                    [title]="''" 
                    [caption]="'permissions to revoke'"
                    [displayedColumns]="permissionsDisplayedColumns" 
                    [selectableRecords]="false"
                    [deletableRecords]="true"
                    [showPagination]="false"
                    [allowLocalFilter]="false"
                    (recordDeleted)="selectPermission($event)">
                    </generic-search-results>
                </fieldset>

                <mat-divider></mat-divider>

                <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar" *ngIf="!isLoading">
                    <mat-spinner [diameter]="30" *ngIf="isSubmitting"
                        style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                    <div class="btn-group mr-2" role="group"
                        *ngIf="!isSubmitting && clientApp !== undefined && submittedRequests.length == 0 && this.selection.selected.length > 0">
                        <button class="button" type="submit" (click)="onSubmit()">submit</button>
                    </div>
                    <div class="btn-group mr-2" role="group" *ngIf="!isSubmitting && clientApp != undefined">
                        <button class="button" type="button" (click)="resetForm()">reset</button>
                    </div>
                </div>


                <display-http-error [message]="submitErrorMessage.message"
                    [errors]="submitErrorMessage.errors"></display-http-error>
            </div>
        </div>
        <div class="row" *ngIf="submittedRequests.length > 0">
            <div class="col col-12">
                <a class="close-button" [routerLink]="['/applications/revoke-permissions']"><span
                        class="icon-remove close-button"></span></a>
                <h1>request submitted</h1>
                <display-http-error [message]="submitErrorMessage.message"
                    [errors]="submitErrorMessage.errors"></display-http-error>
                <display-requests [requests]="submittedRequests"></display-requests>
            </div>
        </div>
    </section>
</main>