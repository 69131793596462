<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <np-alert class="alert-information">
            <p>CAI field is no longer available in some sections. Find out 
              <a href="https://go.chevron.com/IDAMPortalFeatures" target="_blank" rel="noopener">
                more.
              </a> 
            </p>
        </np-alert>
        <div class="row">
            <div class="col col-12">
                <h2>welcome to idamp</h2>

                <p>We've been listening! This new user-friendly, self-service tool will work in connection with Workday,
                    improving identity data consistency across the enterprise.</p>
                <ul>
                    <li>If you are a Chevron Workday-managed employee, you can update 'room number' via the IdAM Portal
                        and 'building' via Workday.</li>
                    <li>Non-employee users within Chevron can be onboarded and managed via this portal.</li>
                    <li>The RRO (Rapid Resource Onboarding) Process will remain in ServiceNow.</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col col-12">
                <div class="utility-bar" style="min-height: 90px; padding-top: 5px;">

                    <a target="_blank" rel="noopener" href="https://go.chevron.com/iamservices"
                        class="button button-text">
                        <img src="/assets/img/Engineering_blue_rgb-50px.png" alt="iam support"> support
                    </a>

                    <a target="_blank" rel="noopener" href="https://go.chevron.com/IAMUserGuides"
                        class="button button-text">
                        <img src="/assets/img/communitysupportmixt-50px.png" alt="user guide"> user guide
                    </a>

                    <a target="_blank" rel="noopener"
                        href="https://chevron.sharepoint.com/sites/cloudhome/SitePages/IdentityNow-FAQ.aspx?OR=Teams-HL&CT=1644264331906&sourceId=&params=%7B%22AppName%22%3A%22Teams-Desktop%22%2C%22AppVersion%22%3A%2227%2F22010300408%22%7D"
                        class="button button-text">
                        <img src="/assets/img/faq-50px.png" alt="faq"> faq
                    </a>

                    <a target="_blank" rel="noopener"
                        href="https://teams.microsoft.com/l/channel/19%3A80f531f0621d4a6ebb73ba1a979673f6%40thread.skype/IdAM%20Portal?groupId=cbcbb55e-3f4c-4560-82aa-45c939a1ffd5&tenantId=fd799da1-bfc1-4234-a91c-72b3a1cb9e26"
                        class="button button-text">
                        <img src="/assets/img/microsoft-people-48.png" alt="teams"> iam teams channel
                    </a>
                </div>
            </div>
        </div>
        <div class="row flex-sm-wrap flex-md-nowrap align-items-center justify-content-center">
            <div class="card col-sm-12 col-md-3 text-dark-blue align-self-stretch">
                <img src="assets/img/blue_lock_275px.png" alt="blue lock">
                <div class="card-content">
                    <h3>new features and enhancements!</h3>
                    <ul>
                        <li>update membership available for on-prem groups</li>
                        <li>access reviews for Entra security groups based on confidentiality tiers</li>
                        <li>CMDB/service ID required for applications and service accounts</li>
                    </ul>
                </div>
            </div>
            <div class="card col-sm-12 col-md-3 text-dark-blue align-self-stretch">
                <img src="assets/img/Identity-Capability-Model.png" alt="feedback">
                <div class="card-content">
                    <h3>digital identity</h3>
                    <p>Learn more about our future of Identity &#64; Chevron</p>
                    <div style="text-align: center; margin-top: 20px;">
                        <a href="https://go.chevron.com/DigitalIdentity" target="_blank" rel="noopener"
                            class="arrow-link">read more</a>
                    </div>
                </div>
            </div>
            <div class="card col-sm-12 col-md-3 text-dark-blue align-self-stretch">
                <img src="assets/img/demo_275px.png" alt="demo">
                <div class="card-content">
                    <h3>watch the walkthrough</h3>
                    <p>Get to know IdAMP by watching recorded demo of the new portal.</p>
                    <div style="text-align: center; margin-top: 20px;">
                        <a href="https://go.chevron.com/IAMUserGuides" target="_blank" rel="noopener"
                            class="arrow-link">read more</a>
                    </div>
                </div>
            </div>
        </div>






    </section>
</main>
